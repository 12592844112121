<template>
  <div class="px-8 py-8 container" style="max-width: 1150px;margin:0 auto;">
    <div class="flex flex-wrap">
      <div class="w-full sm:w-4/5">
        <TitleComponent class="py-8" title="12-meter Drop" size="large" />

        <div class="flex flex-wrap mt-8 p-4" style="background-color:#f7f5f4;">
          <div class="w-full">
            <TitleComponent class="py-8" title="Test Purpose" size="medium" />
            <p class="p-light">
              The purpose of completing the 12-meter drop test is to determine
              whether the substance or article can withstand a forceful impact
              without producing any significant fire or explosion hazard.
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="w-full">
            <TitleComponent class="pt-8" title="Key Parameters" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Key Parameter</th>
                    <th>Objectives</th>
                    <th>Origin</th>
                    <th>Specs</th>
                  </tr>
                  <tr>
                    <td>Drop height to bottom of test unit</td>
                    <td>
                      Provide an impact to the article or substance with
                      sufficient energy representative of transport conditions
                    </td>
                    <td>
                      12 meters is the potential drop height while loading a
                      cargo ship. NOTE: Approximate speed upon impact is 55
                      km/hr (34 mph).
                    </td>
                    <td>12 meters (40 feet)</td>
                  </tr>
                  <tr>
                    <td>Number of trials</td>
                    <td>
                      Sufficient to give a reasonable level of confidence in the
                      reproducibility of the test results
                    </td>
                    <td></td>
                    <td>3 trials/drops with each article only dropped once</td>
                  </tr>
                  <tr>
                    <td>Impact surface (construction and size)</td>
                    <td>
                      Provide a solid base with a reasonably smooth surface for
                      maximum effect that is large enough for free-fall impact
                      with associated variables
                    </td>
                    <td></td>
                    <td>
                      Example of a solid base is a steel plate, at least 75mm
                      (3-in) thick with a Brinell hardness not less than 200, on
                      a concrete foundation, 600mm (24-in) thick minimum; the
                      length and width of the impact surface not less than 1.5
                      times the unit being dropped
                    </td>
                  </tr>
                  <tr>
                    <td>Impact attitude</td>
                    <td>
                      Impact attitude should be such that will most likely
                      result in a reaction
                    </td>
                    <td></td>
                    <td>Impact attitude recorded</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <TitleComponent class="pt-8" title="Indicators" size="medium" />
            <div class="md:px-10 md:pb-10 overflow-x-auto">
              <table class="test-table">
                <tbody>
                  <tr>
                    <th>Indicators</th>
                    <th>Detection Method</th>
                    <th>Assessment</th>
                  </tr>
                  <tr>
                    <td>Fire or explosion</td>
                    <td>Observation of test; video camera evidence</td>
                    <td>
                      For test that result in a fire or explosion (deflagration
                      or detonation) the substance is banned from transport in
                      that configuration; items must be repackaged and retested.
                    </td>
                  </tr>
                  <tr>
                    <td>Rupture of package</td>
                    <td>Visual post-test inspection</td>
                    <td>May be noted but does not affect the conclusion</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="flex flex-wrap mt-8 pb-6" style="background-color:#f7f5f4;">
          <div class="w-full md:w-1/2 md:pr-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Photo"
              size="medium"
            />
            <a
              :href="require('../../assets/UN/nogo12drop-min.jpg')"
              target="_blank"
            >
              <img
                style="width:75%;"
                class="m-auto"
                src="@/assets/UN/nogo12drop-min.jpg"
              />
            </a>
          </div>
          <div class="w-full md:w-1/2 md:pl-2">
            <TitleComponent
              class="pt-8"
              title="No Explosion (-) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/88907707?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo88907707"
              allow="autoplay; fullscreen"
              id="player_1"
              name="fitvid0"
            ></iframe>
            <TitleComponent
              class="pt-8"
              title="Explosion (+) Example Video"
              size="medium"
            />
            <iframe
              class="m-auto"
              src="https://player.vimeo.com/video/116105089?autoplay=0&amp;autopause=0"
              allowfullscreen=""
              title="vimeo116105089"
              allow="autoplay; fullscreen"
              id="player_2"
              name="fitvid1"
            ></iframe>
          </div>
        </div>
      </div>
      <UnSideBar class="w-full sm:w-1/5"></UnSideBar>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import UnSideBar from "@/components/UnSideBar";

export default {
  components: {
    TitleComponent,
    UnSideBar
  }
};
</script>

<style lang="scss"></style>
